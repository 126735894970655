import React from 'react'
import './index.css';

const Footer = () => {
  return (
    <div className='container-footer'>
        &copy; 2023 MELWIN
    </div>
  )
}

export default Footer